// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-bulkorders-details-js": () => import("./../../../src/pages/admin/bulkorders/details.js" /* webpackChunkName: "component---src-pages-admin-bulkorders-details-js" */),
  "component---src-pages-admin-bulkorders-index-js": () => import("./../../../src/pages/admin/bulkorders/index.js" /* webpackChunkName: "component---src-pages-admin-bulkorders-index-js" */),
  "component---src-pages-admin-configuration-index-js": () => import("./../../../src/pages/admin/configuration/index.js" /* webpackChunkName: "component---src-pages-admin-configuration-index-js" */),
  "component---src-pages-admin-customs-detail-js": () => import("./../../../src/pages/admin/customs/detail.js" /* webpackChunkName: "component---src-pages-admin-customs-detail-js" */),
  "component---src-pages-admin-customs-edit-js": () => import("./../../../src/pages/admin/customs/edit.js" /* webpackChunkName: "component---src-pages-admin-customs-edit-js" */),
  "component---src-pages-admin-customs-index-js": () => import("./../../../src/pages/admin/customs/index.js" /* webpackChunkName: "component---src-pages-admin-customs-index-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-login-js": () => import("./../../../src/pages/admin/login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-admin-orders-details-js": () => import("./../../../src/pages/admin/orders/details.js" /* webpackChunkName: "component---src-pages-admin-orders-details-js" */),
  "component---src-pages-admin-orders-edit-js": () => import("./../../../src/pages/admin/orders/edit.js" /* webpackChunkName: "component---src-pages-admin-orders-edit-js" */),
  "component---src-pages-admin-orders-index-js": () => import("./../../../src/pages/admin/orders/index.js" /* webpackChunkName: "component---src-pages-admin-orders-index-js" */),
  "component---src-pages-admin-reports-index-js": () => import("./../../../src/pages/admin/reports/index.js" /* webpackChunkName: "component---src-pages-admin-reports-index-js" */),
  "component---src-pages-admin-shipments-details-box-details-index-js": () => import("./../../../src/pages/admin/shipments/details/box/details/index.js" /* webpackChunkName: "component---src-pages-admin-shipments-details-box-details-index-js" */),
  "component---src-pages-admin-shipments-details-index-js": () => import("./../../../src/pages/admin/shipments/details/index.js" /* webpackChunkName: "component---src-pages-admin-shipments-details-index-js" */),
  "component---src-pages-admin-shipments-index-js": () => import("./../../../src/pages/admin/shipments/index.js" /* webpackChunkName: "component---src-pages-admin-shipments-index-js" */),
  "component---src-pages-admin-users-index-js": () => import("./../../../src/pages/admin/users/index.js" /* webpackChunkName: "component---src-pages-admin-users-index-js" */),
  "component---src-pages-admin-users-sender-details-js": () => import("./../../../src/pages/admin/users/sender/details.js" /* webpackChunkName: "component---src-pages-admin-users-sender-details-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-bulk-[id]-index-js": () => import("./../../../src/pages/bulk/[id]/index.js" /* webpackChunkName: "component---src-pages-bulk-[id]-index-js" */),
  "component---src-pages-bulk-[id]-order-add-js": () => import("./../../../src/pages/bulk/[id]/order/add.js" /* webpackChunkName: "component---src-pages-bulk-[id]-order-add-js" */),
  "component---src-pages-bulk-[id]-order-order-id-js": () => import("./../../../src/pages/bulk/[id]/order/[orderId].js" /* webpackChunkName: "component---src-pages-bulk-[id]-order-order-id-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kiosk-index-js": () => import("./../../../src/pages/kiosk/index.js" /* webpackChunkName: "component---src-pages-kiosk-index-js" */),
  "component---src-pages-kiosk-neworder-js": () => import("./../../../src/pages/kiosk/neworder.js" /* webpackChunkName: "component---src-pages-kiosk-neworder-js" */),
  "component---src-pages-kiosk-register-js": () => import("./../../../src/pages/kiosk/register.js" /* webpackChunkName: "component---src-pages-kiosk-register-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-order-[id]-index-js": () => import("./../../../src/pages/order/[id]/index.js" /* webpackChunkName: "component---src-pages-order-[id]-index-js" */),
  "component---src-pages-order-edit-[id]-js": () => import("./../../../src/pages/order/edit/[id].js" /* webpackChunkName: "component---src-pages-order-edit-[id]-js" */),
  "component---src-pages-order-new-index-js": () => import("./../../../src/pages/order/new/index.js" /* webpackChunkName: "component---src-pages-order-new-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-shipmentstatus-track-id-index-js": () => import("./../../../src/pages/shipmentstatus/[trackId]/index.js" /* webpackChunkName: "component---src-pages-shipmentstatus-track-id-index-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

